/* ONLINE CHATBOX custom configuration goes here (www.olark.com/documentation) */
olark.configure("locale.welcome_title", "Brauchen Sie Hilfe?");
olark.configure("locale.chatting_title", "Der Chat wird gerade verwendet");
olark.configure(
  "locale.welcome_message",
  "Wilkommen! Füllen Sie die Felder aus und schreiben Sie in dem Chat oder suchen Sie im Abschnitt <a href='https://www.lordgun.de/hilfe/faq' target='_blank'>FAQ</a>"
);
olark.configure(
  "locale.chat_input_text",
  "Schreiben Sie und clicken Sie auf Enter, um den Chat anzufangen"
);
olark.configure(
  "locale.ended_chat_message",
  "Der Chat ist zu Ende, wenn Sie weitere Informationen brauchen, schreiben Sie uns nochmal"
);
olark.configure("locale.end_chat_button_text", "Chat beenden");

/* OFFLINE CHATBOX custom configuration goes here (www.olark.com/documentation) */
olark.configure("locale.unavailable_title", "Brauchen Sie Hilfe?");
olark.configure(
  "locale.away_message",
  "Derzeit können unsere Mitarbeiter nicht beantworten. Wenn Sie Hilfe brauchen, füllen Sie bitte das Modul <a href='https://www.lordgun.de/hilfe#contactUs' target='_blank'>Hilfe</a> aus"
);
olark.configure("locale.name_input_text", "Vorname");
olark.configure("locale.email_input_text", "E-Mail");
olark.configure("locale.phone_input_text", "Telefon");
olark.configure(
  "locale.offline_note_message",
  "Chat: Offline. Bitte hinterlassen Sie eine Nachricht"
);
olark.configure("locale.send_button_text", "Senden");
olark.configure(
  "locale.offline_note_sending_text",
  "Die Nachricht wird gesendet..."
);
olark.configure(
  "locale.offline_note_thankyou_text",
  "Danke für Ihre Nachricht! Wir werden uns schnellstmöglich bei Ihnen melden."
);
olark.configure(
  "locale.offline_note_error_text",
  "Alle Felder sind Pflichtfelder und müssen ausgefüllt werden. Schreiben Sie bitte eine gültige E-Mail-Adresse"
);

/* PRE-CHAT SURVEY custom configuration goes here (www.olark.com/documentation) */
olark.configure("locale.name_input_text", "Vorname");
olark.configure("locale.email_input_text", "E-Mail");
olark.configure("locale.phone_input_text", "Telefon");
olark.configure(
  "locale.introduction_error_text",
  "Alle Felder müssen ausgefüllt werden und die angegebene E-Mail-Adresse muss eine gültige Adresse sein."
);
olark.configure(
  "locale.introduction_messages",
  "Willkommen! Füllen Sie die Felder aus und fangen sie den Chat an oder suchen Sie im Abschnitt <a href='https://www.lordgun.de/hilfe/faq' target='_blank'>FAQ</a>"
);
olark.configure("locale.introduction_submit_button_text", "Chat anfangen");
olark.configure("locale.prechat_survey_name_label", "Vorname");
olark.configure("locale.prechat_survey_name_placeholder", "Vorname");

/* CHAT RATINGS custom configuration goes here (www.olark.com/documentation) */
olark.configure(
  "locale.feedback_survey_question_chat_text",
  "Sind Sie mit diesem Chat zufrieden?"
);
olark.configure(
  "locale.feedback_survey_question_operator_intelligence_text",
  "War der Chatmitarbeiter kompetent?"
);
olark.configure(
  "locale.feedback_survey_question_operator_speed_text",
  "War der Chatmitarbeiter flott?"
);
olark.configure(
  "locale.feedback_survey_question_operator_attitude_text",
  "War der Chatmitarbeiter freundlich?"
);
olark.configure("locale.feedback_survey_question_1_text", "Frage 1 von 5");
olark.configure("locale.feedback_survey_question_2_text", "Frage 2 von 5");
olark.configure("locale.feedback_survey_question_3_text", "Frage 3 von 5");
olark.configure("locale.feedback_survey_question_4_text", "Frage 4 von 5");
olark.configure("locale.feedback_survey_question_5_text", "Frage 5 von 5");
olark.configure(
  "locale.feedback_survey_question_chat_low",
  "Gar nicht zufrieden"
);
olark.configure(
  "locale.feedback_survey_question_chat_high",
  "Besonders zufrieden"
);
olark.configure(
  "locale.feedback_survey_question_operator_intelligence_low",
  "Gar nicht kompetent"
);
olark.configure(
  "locale.feedback_survey_question_operator_intelligence_high",
  "Äußerst kompetent"
);
olark.configure(
  "locale.feedback_survey_question_operator_speed_low",
  "Gar nicht flott"
);
olark.configure(
  "locale.feedback_survey_question_operator_speed_high",
  "Äußerst flott"
);
olark.configure(
  "locale.feedback_survey_question_operator_attitude_low",
  "Gar nicht freundlich"
);
olark.configure(
  "locale.feedback_survey_question_operator_attitude_high",
  "Äußerst freundlich"
);
olark.configure(
  "locale.feedback_survey_question_additional_feedback_text",
  "Zusätzlicher Feedback"
);
olark.configure("locale.feedback_survey_button_next", "Weiter");
olark.configure("locale.feedback_survey_button_submitting", "Wird gesendet...");
olark.configure("locale.feedback_survey_button_finish", "Ende");
olark.configure(
  "locale.feedback_survey_end_message",
  "Danke für Ihren Feedback"
);
olark.configure(
  "locale.feedback_survey_submission_error_message",
  "Fehler - Feedback nicht gesendet"
);
olark.configure(
  "locale.feedback_survey_begin_button_text",
  "Bewerten Sie den Chat"
);
olark.configure("locale.feedback_survey_complete_button_text", "Ende");
olark.configure("locale.feedback_survey_cancel_text", "Löschen");
olark.configure("locale.restart_chat_online_button_text", "Chat anfangen");
olark.configure("locale.restart_chat_offline_button_text", "E-Mail");

/* SEND TRANSCRIPTS custom configuration goes here */
olark.configure("locale.send_transcript_title_text", "Transkription senden");
olark.configure(
  "locale.send_transcript_begin_button_text",
  "Transkription senden"
);
olark.configure(
  "locale.send_transcript_complete_button_text",
  "Transkription gesendet"
);
olark.configure(
  "locale.send_transcript_error_text",
  "Fehler - Transkription nicht gesendet"
);
olark.configure("locale.send_transcript_cancel_text", "Beenden");
olark.configure("locale.send_transcript_placeholder", "E-Mail");

/* OPERATOR custom configuration goes here */
olark.configure("locale.operator_is_typing_text", "Am Schreiben...");
olark.configure(
  "locale.operator_has_stopped_typing_text",
  "Schreibt nicht mehr"
);

olark.configure(
  "locale.disabled_input_text_when_convo_has_ended",
  "Chat beendet. Für einen neuen Chat, klicken Sie auf Aktualisieren (F5)"
);
olark.configure(
  "locale.disabled_panel_text_when_convo_has_ended",
  "Das Gespräch ist zu Ende. Für einen neuen Chat aktualisieren Sie die Seite (Taste F5)!"
);
